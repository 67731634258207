import { DataContext } from "DataContainer";
import React, { useContext } from "react";

import { Container, Row, Col, Card } from "reactstrap";
import CardTitle from "reactstrap/lib/CardTitle";

function Sponsor() {
  const groupBy = (key) => (array) =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});

  const { sponsors: allSponsors } = useContext(DataContext);

  const rows = groupBy("panel")(allSponsors);

  if (allSponsors.length === 0) {
    return null;
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <h1
            className="text-center text-g"
            style={{
              fontSize: "3rem",
            }}
          >
            2024 PARTNERS
          </h1>
          {Object.keys(rows).map((r) => (
            <Row>
              {rows[r].map((s) => (
                <Col lg={s.size} xs={6} className={"ml-auto mr-auto pb-3"} key={s.id}>
                  <a href={s.url}>
                    <h4 className="text-center text-400 text-white pb-2">{s.type}</h4>
                    <Card className="rounded-0">
                      <Container fluid>
                        <Row>
                          <Col lg={12}>
                            <img src={s.image} alt={s.name} width="100%" className="rounded-0" />
                          </Col>
                        </Row>
                      </Container>
                    </Card>
                  </a>
                </Col>
              ))}
            </Row>
          ))}
          {/* {rows.map((row) => (
            <Row>
              {row.map((sponsor) =>
                sponsors[sponsor].map((s, i) => (
                  <Col lg={s.lg || 4} className={"mx-auto"} key={i}>
                    <a href={s.link}>
                      <h4 className="text-center text-400 text-white pb-2">{s.type}</h4>
                      <Card style={{ borderRadius: 0, maxWidth: "350px" }} className="mx-auto">
                        <img
                          src={require(`assets/sponsors/${s.image}`)}
                          className="mx-auto"
                          alt={s.name}
                          width="100%"
                          style={{ borderRadius: 0 }}
                        />
                      </Card>
                    </a>
                  </Col>
                ))
              )}
            </Row>
          ))} */}
        </Container>
      </div>
    </>
  );
}

export default Sponsor;
export const sponsorsP = [
  {
    name: "ESRI",
    pageLink: "ESRI",
    type: "GIS PARTNER",
    image: "esri.png",
    text: [""],
    link: "https://www.esri.com/en-us/digital-twin/overview",
    lg: 6,
  },
];
