// reactstrap components
import { Container, Col, Row, Button } from "reactstrap";

import React from "react";
import ParticleBackground from "components/Particles";

function LandingPageHeader({ home }) {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <div
      style={{
        backgroundColor: "#071204",
      }}
    >
      <div
        style={{
          background: `url(${require(size
            ? `assets/images/background9.png`
            : `assets/images/background10.png`)})`,
          backgroudColor: "#071204",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundAttachment: size ? "fixed" : "scroll",
          backgroundSize: "cover",
          paddingTop: "1rem",
          overflow: "hidden",
        }}
        className="page-header"
      >
        {/* <div className="overlay-primary"></div> */}

        <Container style={{ marginTop: !size && "-1rem" }}>
          <Row className="justify-content-center">
            <Col md={7} xs={12}>
              <img
                src={require(`assets/images/logo/DTKSALOGO.png`)}
                style={{ maxWidth: size ? "450px" : "250px" }}
                width="100%"
                alt="dt22"
              />
              <h1
                className="text-700 text-white mt-3"
                style={{
                  fontSize: size ? "2rem" : "1.25rem",
                  // width: size ? "480px" : "330px",
                }}
              >
                RIYADH AIRPORT MARRIOTT HOTEL, RIYADH, KSA
              </h1>
              <div className="d-flex mt-3">
                <div className="mr-4">
                  <div style={{ backgroundColor: "#45a856", padding: "2px" }}>
                    <h1
                      className="text-700 text-white m-0"
                      style={{
                        fontSize: size ? "1.2rem" : "1rem",
                        // width: size ? "480px" : "330px",
                      }}
                    >
                      CONFERENCE
                    </h1>
                  </div>
                  <div style={{ display: "flex" }}>
                    <h1
                      className="text-700 text-white m-0 border-right pr-2"
                      style={{
                        fontSize: "3rem",
                        // width: size ? "480px" : "330px",
                      }}
                    >
                      8
                    </h1>
                    <h1
                      className="text-700 text-white m-0 pl-2 align-self-center"
                      style={{
                        fontSize: "1rem",
                        // width: size ? "480px" : "330px",
                      }}
                    >
                      OCTOBER
                      <br />
                      2024
                    </h1>
                  </div>
                </div>
                <div>
                  <div style={{ backgroundColor: "#45a856", padding: "2px" }}>
                    <h1
                      className="text-700 text-white m-0"
                      style={{
                        fontSize: size ? "1.2rem" : "1rem",
                        // width: size ? "480px" : "330px",
                      }}
                    >
                      WORKSHOPS
                    </h1>
                  </div>
                  <div style={{ display: "flex" }}>
                    <h1
                      className="text-700 text-white m-0 border-right pr-2"
                      style={{
                        fontSize: "3rem",
                        // width: size ? "480px" : "330px",
                      }}
                    >
                      9
                    </h1>
                    <h1
                      className="text-700 text-white m-0 pl-2 align-self-center"
                      style={{
                        fontSize: "1rem",
                        // width: size ? "480px" : "330px",
                      }}
                    >
                      OCTOBER
                      <br />
                      2024
                    </h1>
                  </div>
                </div>
              </div>

              <br />

              <div className="container-fluid row mt-1">
                <Col lg={12} xs={12} className="px-0 d-flex">
                  <Button
                    href="/register"
                    className="rounded-0 navbar-item-custom text-400 text-white px-4 mr-2"
                    color="primary"
                    size="lg"
                    target="_blank"
                    style={{ background: "#5ab030" }}
                    rel="noopener noreferrer"
                  >
                    <span
                      style={{
                        color: "#fff",
                        textTransform: "none",
                        fontSize: size ? "20px" : 12,
                        fontWeight: 700,
                      }}
                    >
                      REGISTER NOW
                    </span>
                  </Button>{" "}
                  <Button
                    href="/request-agenda"
                    className="rounded-0 navbar-item-custom text-400 text-white px-4"
                    color="primary"
                    size="lg"
                    target="_blank"
                    style={{ background: "#5ab030" }}
                    rel="noopener noreferrer"
                  >
                    <span
                      style={{
                        color: "#fff",
                        textTransform: "none",
                        fontSize: size ? "20px" : 12,
                        fontWeight: 700,
                      }}
                    >
                      REQUEST AGENDA
                    </span>
                  </Button>
                </Col>
              </div>
            </Col>

            {/* <Col md={6} xs={12} className="mt-4"></Col> */}
          </Row>
        </Container>
        <ParticleBackground />
      </div>
    </div>
  );
}

export default LandingPageHeader;
