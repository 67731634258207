import { apiUrl, newToken } from "./constants";
import { apiToken } from "./constants";
import React, { useEffect, useState } from "react";
import { createContext } from "react";

export const DataContext = createContext({});

export default function DataContainer({ children }) {
  const [data, setData] = useState({
    agenda: [],
    speakers: [],
    sponsors: [],
  });
  const [pastData, setPastData] = useState({
    pastSpeakers: [],
  });
  async function fetchData() {
    await fetch(`${apiUrl}/getEventData`, {
      method: "get",
      headers: new Headers({
        Authorization: apiToken,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setPastData({ pastSpeakers: data.speakers });
      })
      .catch((err) => console.log(err));

    await fetch(`${apiUrl}/getEventData`, {
      method: "get",
      headers: new Headers({
        Authorization: newToken,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setData({ speakers: data.speakers, sponsors: data.sponsors });
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    fetchData();
  }, []);

  return <DataContext.Provider value={{ ...data, ...pastData }}>{children}</DataContext.Provider>;
}
