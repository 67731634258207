import React, { useEffect } from "react";

function RegisterPage() {
  useEffect(() => {
    window.location.href =
      "https://explat-space.ams3.digitaloceanspaces.com/d9e03332-1d55-11ee-be56-0242ac120012/brochure.pdf";
  }, []);
  return <></>;
}
export default RegisterPage;
