/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";

import "assets/css/paper-kit.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "assets/demo/demo.css";

import Home from "./views/home.js";

import RegisterPage from "./views/Register.js";
import ThankYouRegister from "./views/ThankYouRegister";

import Download from "./views/download";
import QRView from "./views/QRView";
import QRRedirect from "./views/QRRedirect";
import DelegateInfo from "./views/DelegateInfo";
import DataProvider from "./DataContainer";
import PastEvents from "./views/pastEvents";
import PastSpeakers from "./views/pastspeakers.js";

ReactDOM.render(
  <DataProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact render={(props) => <Home {...props} />} />
        <Route
          path="/register"
          exact
          render={(props) => <RegisterPage {...props} type="REGISTER" title="REGISTER NOW" />}
        />
        <Route
          path="/register/:id"
          exact
          render={(props) => <RegisterPage {...props} type="REGISTER" title="REGISTER NOW" />}
        />
        <Route
          path="/workshop"
          exact
          render={(props) => <RegisterPage {...props} type="WORKSHOP" title="REGISTER" />}
        />
        <Route
          path="/recording"
          exact
          render={(props) => <RegisterPage {...props} type="REPORT" title="View Event Recording" />}
        />
        <Route
          path="/request-agenda"
          exact
          render={(props) => <RegisterPage {...props} type="AGENDA" title="REQUEST AGENDA" />}
        />

        <Route path="/thank-you/:id" exact render={(props) => <ThankYouRegister {...props} />} />
        <Route path="/download-brochure" exact render={(props) => <Download {...props} />} />
        <Route path="/view-qr" exact render={(props) => <QRView {...props} />} />
        <Route path="/qr-redirect/:id" exact render={(props) => <QRRedirect {...props} />} />
        <Route path="/delegateInfo/:id" exact render={(props) => <DelegateInfo {...props} />} />
        <Route path="/past-events" exact render={(props) => <PastEvents {...props} />} />
        <Route path="/past-speakers" exact render={(props) => <PastSpeakers {...props} />} />
      </Switch>
    </BrowserRouter>
  </DataProvider>,
  document.getElementById("root")
);
