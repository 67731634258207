import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";

import Footer from "components/Footer.js";
import { Container, Row, Col } from "reactstrap";
import { apiUrl, apiToken, newToken } from "../constants";

function QRView() {
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const type = urlParams.get("t");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const getDelegateData = () => {
    setLoading(true);
    fetch(`${apiUrl}/getDelegateData/${id}`, {
      method: "get",
      headers: new Headers({
        Authorization: newToken,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const [row] = (data && data.rows) || [{}];
        const delegate = {
          name: `${row.firstName} ${row.lastName}`,
          title: row.title,
          company: row.company,
          country: row.country,
          email: row.email,
          phone: row.phone,
          id: row.id,
          shortId: row.shortId,
          type: "DELEGATE",
        };
        setData(delegate);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };
  const getSpeakerData = () => {
    setLoading(true);
    fetch(`${apiUrl}/getSpeakerData/${id}`, {
      method: "get",
      headers: new Headers({
        Authorization: newToken,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const [row] = (data && data.rows) || [{}];
        const speaker = {
          name: row.name,
          title: row.title,
          company: row.company,
          country: row.country,
          email: row.email,
          phone: row.phone,
          id: row.id,
          type: "SPEAKER",
        };
        setData(speaker);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (type === "s") {
      getSpeakerData();
    } else {
      getDelegateData();
    }
  }, [type]);

  return (
    <>
      <Navbar />
      {data.id ? (
        <>
          <div style={{}} className="section pt-5 pb-0" data-parallax={true}></div>
          <div
            className="section section-with-space"
            style={{
              background: "url(" + require("assets/images/green-grunge-paper-background.jpg") + ")",
              backgroundAttachment: "fixed",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              minHeight: "70vh",
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              alignItems: "center",
            }}
            data-parallax={true}
          >
            <Container className="">
              <Row className="justify-content-center">
                <Col lg={12}>
                  <h1
                    className="text-700 text-center text-g mb-3"
                    style={{
                      fontSize: "3rem",
                    }}
                  >
                    {`${data.type} PASS`}
                  </h1>
                </Col>
                <div className="col-lg-4 pb">
                  <div className="card text-center">
                    <div className="card-body">
                      <h3 className="text-700 mt-0 s">{data.name}</h3>
                      <p className="text-400">{data.title}</p>
                      <h3 className=" text-700 pt-0">
                        {data.company}, {data.country}
                      </h3>
                      <p className="mt-3">
                        <a
                          style={{ color: "", wordBreak: "break-word" }}
                          href={`mailto:${data.email}`}
                        >
                          {data.email}
                        </a>
                      </p>
                      {data.phone && (
                        <p className="card-text">
                          <a style={{ color: "#5ab02f" }} href={`tel:${data.phone}`}>
                            {data.phone}
                          </a>
                        </p>
                      )}
                    </div>
                    <div className="card-footer mb-2">
                      <QRCode value={`${apiUrl}/${data.shortId}/${type}`} />
                    </div>
                  </div>
                </div>
                {/*  */}
              </Row>
            </Container>
          </div>
        </>
      ) : (
        <div
          className="section section-with-space"
          style={{
            background: "url(" + require("assets/images/green-grunge-paper-background.jpg") + ")",
            backgroundAttachment: "fixed",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            minHeight: "75vh",
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            alignItems: "center",
          }}
          data-parallax={true}
        >
          <h1
            className="text-700 text-center text-g mb-3"
            style={{
              fontSize: "3rem",
            }}
          >
            {loading ? "LOADING ...." : `No Data`}
          </h1>
        </div>
      )}

      <Footer />
    </>
  );
}

export default QRView;
