import React from "react";

import { Container, Row, Col, Card } from "reactstrap";
import CardTitle from "reactstrap/lib/CardTitle";
import Slider from "react-slick";

function Sponsor() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  const rows = [
    ["CO-ORGANIZER", "SUPPORTED BY"],
    ["VIRTUAL TWIN PARTNER", "DIGITAL TRANSFORMATION PARTNER", "DIGITAL TWIN PARTNER"],
    ["SILVER SPONSOR"],
    ["BRONZE SPONSOR"],
    ["EXHIBITOR"],

    ["VISITING PARTNER", "LOCAL PARTNER"],
    ["SUPPORTING ASSOCIATION", "SUPPORTING PARTNER", "KNOWLEDGE PARTNER"],
  ];

  const settings = {
    // dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 1500,
    slidesToShow: 4,
    slidesToScroll: 2,
    rows: 3,
    className: "p-10",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          row: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          row: 1,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <h1
            className="text-center text-i"
            style={{
              fontSize: "3rem",
              color: "#000",
            }}
          >
            PAST EDITION PARTNERS
          </h1>

          <Row>
            <Col lg={12} className={"mx-auto"}>
              <Slider {...settings}>
                {sponsorsP.map((s, i) => (
                  <a href={s.link} key={i} style={{ padding: "10px" }}>
                    {/* <h4 className="text-center text-400 text-i pb-2">{s.type}</h4> */}
                    <Card
                      style={{ borderRadius: 0, maxWidth: "350px", boxShadow: "none" }}
                      className="mx-auto"
                    >
                      <img
                        src={require(`assets/sponsors/${s.image}`)}
                        className="mx-auto"
                        alt={s.name}
                        width="100%"
                        style={{ borderRadius: 0 }}
                      />
                    </Card>
                  </a>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Sponsor;
export const sponsorsP = [
  {
    name: "Saudi Contractors Authority",
    pageLink: "Saudi Contractor Authority",
    type: "CO-ORGANIZER",
    image: "sclogo.jpg",
    text: [""],
    link: "",
    lg: 4,
  },
  {
    name: "DASSAULT SYSTEMES",
    pageLink: "DASSAULT SYSTEMES",
    type: "VIRTUAL TWIN PARTNER",
    image: "dassault.png",
    text: [""],
    link: "",
    lg: 4,
  },
  {
    name: "SoftwareOne",
    pageLink: "SoftwareOne",
    type: "DIGITAL TRANSFORMATION PARTNER",
    image: "softwareone.png",
    text: [""],
    link: "",
    lg: 4,
  },
  {
    name: "Siemens",
    pageLink: "Siemens",
    type: "DIGITAL TWIN PARTNER",
    image: "siemens.png",
    text: [""],
    link: "https://new.siemens.com/",
    lg: 4,
  },
  {
    name: "Modon",
    pageLink: "Modon",
    type: "SUPPORTED BY",
    image: "modon.jpg",
    text: [""],
    link: "https://modon.gov.sa/ar/Pages/default.aspx",
    lg: 4,
  },
  {
    name: "Trimble",
    pageLink: "Trimble",
    type: "SILVER SPONSOR",
    image: "Trimble.png",
    text: [""],
    link: "https://www.trimble.com/en/",
    lg: 4,
  },
  {
    name: "Alkholi Group",
    pageLink: "Alkholi Group",
    type: "SILVER SPONSOR",
    image: "Alkholi Group.png",
    text: [""],
    link: "https://www.alkholi.com/",
    lg: 4,
  },
  {
    name: "NineTenths and Dji Enterprise",
    pageLink: "NineTenths and Dji Enterprise",
    type: "SILVER SPONSOR",
    image: "ninetenth.png",
    text: [""],
    link: "https://www.9tenthsco.com/construction",
    lg: 4,
  },
  {
    name: "Twinzo",
    pageLink: "Twinzo",
    type: "SILVER SPONSOR",
    image: "twinzo.png",
    text: [""],
    link: "https://twinzo.eu/",
    lg: 4,
  },

  {
    name: "JACOBS",
    pageLink: "JACOBS",
    type: "BRONZE SPONSOR",
    image: "jacobs.png",
    text: [""],
    link: "https://www.jacobs.com/",
    lg: 4,
  },

  {
    name: "GEOMATEX",
    pageLink: "GEOMATEX",
    type: "EXHIBITOR",
    image: "geomatex.png",
    text: [""],
    link: "https://www.geomatex.com/",
    lg: 4,
  },
  // {
  //   name: "cadmasters",
  //   pageLink: "cadmasters",
  //   type: "EXHIBITOR",
  //   image: "cadmasters.jpg",
  //   text: [""],
  //   link: "http://cadmasters.org/",
  //   lg: 6,
  // },
  {
    name: "PlanRadar",
    pageLink: "PlanRadar",
    type: "EXHIBITOR",
    image: "planradar.jpg",
    text: [""],
    link: "https://www.planradar.com/",
    lg: 4,
  },
  {
    name: "ASITE",
    pageLink: "ASITE",
    type: "EXHIBITOR",
    image: "asite.jpg",
    text: [""],
    link: "https://www.asite.com/",
    lg: 4,
  },

  {
    name: "DUNCAN & ROSS",
    pageLink: "DUNCAN & ROSS",
    type: "EXHIBITOR",
    image: "dr.png",
    text: [""],
    link: "https://www.duncan-ross.com/",
    lg: 4,
  },
  {
    name: "Minmap Technology | Geotech Overseas",
    pageLink: "Minmap Technology | Geotech Overseas",
    type: "EXHIBITOR",
    image: "minmap.png",
    text: [""],
    link: "https://www.geotechoverseas.com.sa/",
    lg: 4,
  },
  {
    name: "Gallium",
    pageLink: "Gallium",
    type: "EXHIBITOR",
    image: "gallium.png",
    text: [""],
    link: "https://www.thegallium.com/",
    lg: 4,
  },
  {
    name: "Digitadis",
    pageLink: "Digitadis",
    type: "EXHIBITOR",
    image: "logo digitadis-01.png",
    text: [""],
    link: "https://digitadis.com/",
    lg: 4,
  },

  {
    name: "Heriot Watt University",
    pageLink: "heriotwatt",
    type: "KNOWLEDGE PARTNER",
    image: "Heriot.jpg",
    text: [""],
    link: "https://www.hw.ac.uk/dubai/research/smart-construction.htm",
    lg: 4,
  },
  {
    name: "ESRI",
    pageLink: "ESRI",
    type: "VISITING PARTNER",
    image: "esri.png",
    text: [""],
    link: "https://www.esrisaudiarabia.com/index.html",
    lg: 4,
  },
  {
    name: "Cadmatic and Almutlaq United",
    pageLink: "Cadmatic-Almutlaq-United",
    type: "VISITING PARTNER",
    image: "cadamatic.png",
    text: [""],
    link: "https://www.cadmatic.com/",
    lg: 4,
  },

  {
    name: "Marketing Communications",
    pageLink: "Marketing Communications",
    type: "LOCAL PARTNER",
    image: "mc.jpg",
    text: [""],
    link: "https://mc-summits.com/",
    lg: 4,
  },
  {
    name: "Digital Twin",
    pageLink: "digitaltwin",
    type: "SUPPORTING PARTNER",
    image: "twin.png",
    text: [""],
    link: "https://www.digitaltwinconsortium.org/",
    lg: 4,
  },

  {
    name: "RICS",
    pageLink: "RICS",
    type: "SUPPORTING PARTNER",
    image: "RICS.png",
    text: [""],
    link: "https://www.rics.org/en-in/",
    lg: 4,
  },
  {
    name: "CIOB",
    pageLink: "CIOB",
    type: "SUPPORTING PARTNER",
    image: "CIOB.png",
    text: [""],
    link: "https://www.ciob.org/",
    lg: 4,
  },
  {
    name: "PMI KSA",
    pageLink: "PMI KSA",
    type: "SUPPORTING ASSOCIATION",
    image: "pmi.png",
    text: [""],
    link: "https://pmiksa.sa",
    lg: 4,
  },
];

const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByType = groupBy("type");
export const sponsors = groupByType(sponsorsP);
