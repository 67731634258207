import React from "react";

import { Container, Row, Col, Card } from "reactstrap";
// import CountUp from 'react-countup';

import "react-circular-progressbar/dist/styles.css";

function AboutPoints() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        className="section py-5"
        style={{
          background: "transparent",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col className="ml-auto mr-auto text-dark" md={12}>
              <h1
                className="text-center text-g mb-5"
                style={{
                  fontSize: size ? "3rem" : "2rem",
                }}
              >
                KEY TOPICS
              </h1>
            </Col>

            {content.map((c, i) => (
              <Col xs={11} lg={c.lg || 4} className="pr-0">
                <div className="container-fluid row justify-content-center col-lg-11 col-xs-12 pr-0">
                  <Card
                    className="rounded-0 incomplete-box"
                    style={{
                      background: "transparent",
                      // border: "5px #5ab030 solid",
                    }}
                  >
                    <div className="container-fluid row ml-1 py-2">
                      <Col xs={2} className="align-self-center">
                        <img
                          src={require(`assets/icons/${c.image}`)}
                          style={{
                            minWidth: "80px",
                            marginLeft: "-60px",
                          }}
                          width="100%"
                          alt="about"
                          // className="mx-auto"
                        />
                      </Col>
                      <Col xs={10} className="align-self-center">
                        <p
                          className="text-400 mt-2 text-white"
                          dangerouslySetInnerHTML={{ __html: c.title }}
                        />
                      </Col>
                    </div>
                  </Card>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AboutPoints;

// Digital Twins for immersive experiences for people in building cities and ultra-luxury destinations
// Case studies on Digital Twin implementation for Construction for Saudi Arabia’s Giga projects– value of Digital Twins on each business line and understanding the ROI
// Panel discussions – Standards, Policies, Contracts & Procurement in the Digital Twin ecosystem

const content = [
  {
    title: `<span class="text-f text-bold">
    Formulating a realistic Roadmap for Digital Twins:
</span>
<br /> <span class="text-f text-bold">What</span> is a Digital
Twin?
<br /> <span class="text-f text-bold">What is Not</span> a Digital Twin? `,
    image: "17.png",
    lg: 6,
  },
  {
    title: `
  <span class="text-f text-bold">Implementing Digital Twins on Design, Construction & Maintenance of 
  </span>assets
  `,
    image: "22.png",
  },

  {
    title: `
  <span class="text-f text-bold">Data and Twins–</span> value of Data on business decisions across organizational functions
<br/>  <span class="text-f text-bold">Data policies in Saudi Arabia and its impact on implementing Digital Twins</span>
  `,
    image: "19.png",
  },
  {
    title: `
  <span class="text-f text-bold">Digital Twins for immersive experiences for people in building cities and <i>ultra-luxury</i> destinations</span>
  `,
    image: "59.png",
  },
  {
    title: ` <span class="text-f text-bold">Case studies on Digital Twin implementation –</span> for Construction for Saudi Arabia’s Giga projects
  
  <span class="text-f text-bold"> - value of Digital Twins on each business line and understanding the ROI </span>
  `,
    image: "16.png",
    lg: 6,
  },
  {
    title: `
  <span class="text-f text-bold">Achieving Net Zero commitments via Digital Twins</span>
  `,
    image: "18.png",
  },

  {
    title: ` <span class="text-f text-bold">Panel discussions –</span> Standards, Policies, Contracts & Procurement in the Digital Twin ecosystem
  `,
    image: "29-2.png",
    lg: 6,
  },
];
